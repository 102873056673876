tr {
  td {
    @media (min-width: 768px) {
      line-height: 40px;
    }
    @media not all and (min-width: 768px) {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  td:first-child {
    width: 20px;
    font-size: 32px;
    position: relative;
    top: -3px;
  }
  td:nth-child(2) {
    width: 120px;
  }
  em {
    font-style: normal;
  }
  &.personal {
    td:first-child, em {
      color: red;
    }
  }
  &.professional {
    td:first-child, em {
      color: deepskyblue;
    }
  }
}
